/*=========================================================================================
  File Name: moduleSmokeDegreeMutations.js
  Description: SmokeDegree Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.SmokeDegrees.unshift(item)
  },
  SET_SmokeDegree(state, SmokeDegrees) {
    state.SmokeDegrees = SmokeDegrees
  },
  UPDATE_SmokeDegree(state, SmokeDegree) {
    const SmokeDegreeIndex = state.SmokeDegrees.findIndex((p) => p.ID == SmokeDegree.ID)
    Object.assign(state.SmokeDegrees[SmokeDegreeIndex], SmokeDegree)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.SmokeDegrees.findIndex((p) => p.ID == itemId)
    state.SmokeDegrees.splice(ItemIndex, 1)
},
}
